<template>
  <main class="content">
    <p class="content-subtitle" :class="{ heaven: isHeaven }">
      {{ $t("views.create_product.subtitle") }}
    </p>
    <section class="content-types">
      <b-skeleton v-if="isLoading" animation="wave" width="260px" height="334px" data-anima="bottom"></b-skeleton>
      <b-skeleton v-if="isLoading" animation="wave" width="260px" height="334px" data-anima="bottom"></b-skeleton>
      <b-skeleton v-if="isLoading" animation="wave" width="260px" height="334px" data-anima="bottom"></b-skeleton>
      <b-skeleton v-if="isLoading" animation="wave" width="260px" height="334px" data-anima="bottom"></b-skeleton>
      <template>
        <section class="content-types-item" v-for="(item, index) in types" :key="index" @click="item.action"
          data-anima="top">
          <img v-if="isHeaven" class="content-types-item-icon" :src="require(`@/assets/img/heaven-${item.icon}`)" />
          <img v-else class="content-types-item-icon" :src="require(`@/assets/img/${item.icon}`)" />
          <p class="content-types-item-name">{{ item.name }}</p>
          <p class="content-types-item-description">{{ item.description }}</p>
        </section>
      </template>
    </section>
  </main>
</template>
<script>
export default {
  name: "Types",
  computed: {
    isLoading() {
      return this.$store.state.createProduct.isLoading;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    types() {
      return this.$store.state.createProduct.types.map((type) => ({
        ...type,
        name: this.$t(`views.seller.create_product.${type.type}`),
        description: type.description,
        icon: this.setTypeIcon(type.type),
        action: () => {
          this.$store.commit("createProduct/setCurrentStep", 2);
          this.$store.commit("createProduct/resetProduct");
          this.$store.commit("createProduct/setCurrentType", type);
          this.$router.replace(`/novo-produto/${type.type}`);
        },
      }));
    },
  },
  methods: {
    setTypeIcon(name) {
      switch (name) {
        case "mentoring":
          return "create_product-mentoring.svg";
        case "course":
          return "create_product-play.svg";
        case "event":
          return "create_product-event.svg";
        case "others":
          return "create_product-others.svg";
        case "physical_product":
          return "create-product-physical.svg";
        case "file":
          return "create_product-file.svg";
        default:
          return "create_product-mentoring.svg";
      }
    },
  },
  mounted() {
    this.$store.commit("createProduct/setCurrentStep", 1);
    this.$store.commit("createProduct/resolveCurrentChecklist");
  },
};
</script>
<style lang="scss" scoped>
p,
span {
  font-size: 18px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.heaven {
  color: $base-color-heaven !important;
}

.content {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 40px !important;
  gap: 35px;

  &-subtitle {
    color: $base-color;
  }

  &-types {
    display: grid;
    gap: 20px;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;

    &-item {
      border-radius: 10px;
      border: 1px solid #e5e5e5;
      padding: 25px 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      transition: ease-in-out 200ms;
      cursor: pointer;

      &:hover {
        box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25);
      }

      &-icon {
        width: 50px;
        height: 50px;
      }

      &-name {
        font-size: 16px;
        color: black;
      }

      &-description {
        color: #81858e;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
</style>